import { AccountsService } from './accounts/accounts.service';
import { ChannelsService } from './channels/channels.service';
import { DevicesService } from './devices/devices.service';
import { FaqsService } from './faqs/faqs.service';
import { MarketingContentsService } from './marketing-contents/marketing-contents.service';
import { PagesService } from './pages/pages.service';
import { PromotionsService } from './promotions/promotions.service';
import { ResourcesService } from './resources/resources.service';
import { StreamKeysService } from './stream-keys/stream-keys.service';
import { SubscriberContentsService } from './subscriber-contents/subscriber-contents.service';
import { SubscriptionsService } from './subscriptions/subscriptions.service';
import { TextCommunicationsService } from './text-communications/text-communications.service';
import { UsersService } from './users/users.service';
import { VideoUserDataService } from './video-user-data/video-user-data.service';
import { VideosService } from './videos/videos.service';

export const services = [
  AccountsService,
  ChannelsService,
  DevicesService,
  FaqsService,
  MarketingContentsService,
  PagesService,
  PromotionsService,
  ResourcesService,
  StreamKeysService,
  SubscriberContentsService,
  SubscriptionsService,
  TextCommunicationsService,
  UsersService,
  VideoUserDataService,
  VideosService
];

export * from './accounts/accounts.service';
export * from './channels/channels.service';
export * from './devices/devices.service';
export * from './faqs/faqs.service';
export * from './marketing-contents/marketing-contents.service';
export * from './pages/pages.service';
export * from './promotions/promotions.service';
export * from './resources/resources.service';
export * from './stream-keys/stream-keys.service';
export * from './subscriber-contents/subscriber-contents.service';
export * from './subscriptions/subscriptions.service';
export * from './text-communications/text-communications.service';
export * from './users/users.service';
export * from './video-user-data/video-user-data.service';
export * from './videos/videos.service';
