import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as fromActions from '../../actions/subscribe/subscribe.actions';
import * as fromDevices from '../../../../../core/store/actions/device/device.actions';
import { SubscriptionsService } from '../../../../../api/services';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { unwrap } from 'src/app/util/code.util';

@Injectable()
export class SubscribeEffects {
  subscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribe),
      mergeMap(({ subscription }) =>
        this.subscriptionsService.subscribe(subscription).pipe(
          tap(() => {
            const couponData = unwrap(subscription, 'couponData') || {};
            const planData = unwrap(subscription, 'planData') || {};
            const data = {
              transaction_id: subscription['stripe_source_token'],
              affiliation: 'Odeum Web Client',
              value: couponData['new_amount'] || planData['amount'],
              currency: 'USD',
              items: [
                {
                  id: planData['id'],
                  name: planData['name'],
                  price: planData['amount']/100
                }
              ]
            };
            if (couponData['description']) {
              data['coupon'] = couponData['description'];
            }
            this.ga.event('purchase', data);
          }),
          mergeMap(resp =>
            of(
              fromActions.subscribeSuccess({ email: subscription.email }),
              fromDevices.saveDeviceSuccess({ token: resp['user_token'] }),
            )
          ),
          catchError(error => of(fromActions.subscribeFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subscriptionsService: SubscriptionsService,
    private ga: GoogleAnalyticsService
  ) {}
}
