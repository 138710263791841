import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromRoot from '../../../store';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'odm-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyComponent implements OnInit {
  account$: Observable<any>;
  constructor(private store: Store<any>, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromStore.selectAccount));
  }

  get iframeSrc(): any {
    let iframeUrl = "https://www.iubenda.com/privacy-policy/28723254/full-legal";
    return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }
}
